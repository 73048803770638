<template>
  <div class="app-container">
    <div class="right_btns">
      <el-button class="filter-item1" type="primary" size="small" @click="approve" v-if="listQuery.status == 1"   >
        <el-icon><Pointer /></el-icon><span  >认证</span>
      </el-button>
      <el-button class="filter-item1" type="primary" size="small" @click="unApprove" v-if="listQuery.status == 2"   >
        <el-icon><Pointer /></el-icon><span  >取消认证</span>
      </el-button>
      <settings name="设置" :type="['code_wrzjxs']"></settings>
    </div>
    <el-tabs v-model="listQuery.status" @tab-click="handleClick">
      <el-tab-pane label="未认证" :name="1"></el-tab-pane>
      <el-tab-pane label="本月认证" :name="2"></el-tab-pane>
    </el-tabs>
    <div style="margin-bottom:10px">
      <el-input size="small" v-model="listQuery.fphm" style="width:120px" placeholder="发票号码"></el-input>
      <el-button style="margin-right: 5px;" size="small" @click="getList" type="primary" :icon="Search">
        <el-icon><Search /></el-icon><span  > 搜索</span>
      </el-button>
    </div>
    <el-table stripe :data="list" style="width: 100%" :height="contentStyleObj" @selection-change="handleSelectionChange" border>
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column align="center" prop="period" label="账期" width="180" />
      <el-table-column align="center" prop="fpdm" label="发票代码" width="180" />
      <el-table-column align="center" prop="fphm" label="发票号码" />
      <el-table-column align="center" prop="corpName" label="公司名称" />
      <el-table-column align="center" prop="invoiceType" label="发票类型">
        <template #default="scope">
          <span v-if="scope.row.invoiceType == '1'">专票</span>
          <span v-else-if="scope.row.invoiceType == '2'">普票</span>
          <span v-else-if="scope.row.invoiceType == '3'">农产品发票</span>
          <span v-else-if="scope.row.invoiceType == '4'">电子专票</span>
          <span v-else-if="scope.row.invoiceType == '5'">电子普票</span>
          <span v-else-if="scope.row.invoiceType == '6'">海关缴款书</span>
          <span v-else-if="scope.row.invoiceType == '7'">其他发票</span>
        </template>
      </el-table-column>
      <el-table-column align="center" v-if="listQuery.status == 2" prop="periodZz" label="认证账期" />
      <el-table-column align="center" v-if="listQuery.status == 2" prop="voucherNo" label="凭证号" />
      <el-table-column align="center" prop="sumAmount" label="金额" />
      <el-table-column align="center" prop="sumTax" label="税额" />
      <el-table-column align="center" prop="sumTotal" label="价税合计" />
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
  </div>
</template>

<script>
import { inInvoiceWzzList,uncertifiedSave,uncertifiedSaveNo } from "@/api/invoice"
export default {
  name:'incomeWzz',
  data() {
    return {
      list: [],
      total: 0,
      listQuery:{
        page: 1,
        limit: 20,
        status:1,
      },
      gsMainIds: undefined
    }
  },
  created() {
    this.contentStyleObj = this.$getHeight(290)
    this.getList()
  },
  methods:{
    getList() {
      inInvoiceWzzList(this.listQuery).then(res=>{
        if(res.data.data.list){
          this.list = res.data.data.list
          this.total = res.data.data.total
        }else{
          this.list = []
          this.total = 0
        }
      })
    },
    handleSelectionChange(row) {
      this.gsMainIds = []
      row.map(info=>{
        this.gsMainIds.push({id:info.id})
      })
    },
    approve() {
      this.$confirm('确定要认证吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        uncertifiedSave(this.gsMainIds).then(res => {
          if(res.data.msg == 'success') {
            this.$qzfMessage('已认证！',3)
            this.getList()
          }
        })
      })
    },
    unApprove() {
      this.$confirm('确定取消认证吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        uncertifiedSaveNo(this.gsMainIds).then(res => {
          if(res.data.msg == 'success') {
            this.$qzfMessage('已取消认证！',3)
            this.getList()
          }
        })
      })
    },
    handleClick(tab) {
      if(tab.props.name == 1) {
        this.listQuery.status = 1
        this.getList()
      }if(tab.props.name == 2) {
        this.listQuery.status = 2
        this.getList()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.right_btns{
  position: absolute;
  right: 22px;
  z-index: 99;
}
</style>